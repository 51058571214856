<template>
  <v-container>
    <v-row style="justify-content: center;">
      <v-col cols="6">
        <div class="login">
          <div style="position: relative">
            <p>Adresse e-mail</p>
            <v-text-field
              v-model="email"
              color="#000091"
              outlined
              @focus="emailError = false"
            />
            <small
              v-if="emailError"
              class="error-msg"
            >{{ error }}</small>
          </div>
          <div style="position: relative; padding-top: 20px;">
            <p>Mot de passe</p>
            <v-text-field
              v-model="password"
              color="#000091"
              :type="showPwd ? 'text' : 'password'"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              @focus="passwordError = false"
              @click:append="showPwd = !showPwd"
              @keyup="keyup"
            />
            <small
              v-if="passwordError"
              class="error-msg"
            >{{ error }}</small>
            <small
              v-if="authError"
              class="error-msg error-auth"
            >{{ error }}</small>
          </div>
          <div style="text-align: center;">
            <v-btn
              class="cnxButton bg-cep-blue"
              color="#000091"
              @click="onLogin"
            >
              connexion
              <v-icon
                right
                dark
              >
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        password: '',
        showPwd: false,
        error: null,
        email: '',
        emailError: false,
        passwordError: false,
        authError: false,
        breakpoint: {
          xs: false,
        },
      };
    },
    methods: {
      keyup(e) {
        this.emailError = null;
        this.passwordError = null;
        this.authError = null;
        if (e.keyCode === 13) {
          this.onLogin();
        }
      },
      async onLogin() {
        const isValidEmail = this.isValidEmail(this.email);
        const isValidPassword = this.isNotEmpty(this.password);
        if (isValidEmail && isValidPassword) {
          try {
            const loginResult = await this.$auth.login({
              data: {
                identifier: this.email,
                password: this.password,
              },
            });

            if(loginResult.status === 200) {
              const { username, email, role } = loginResult.data.user;
              await this.$auth.user({
                username,
                email,
                role: role.name,
              });
              localStorage.setItem('token', loginResult.data.jwt);
              this.axios.defaults.headers.common['Authorization'] = `Bearer ${loginResult.data.jwt}`;
              this.$router.push({ name: 'Dashboard' });
            }
          } catch (e) {
            this.authError = true;
            this.error = 'Vos identifiants sont erronés. Veuillez ré-essayer.';
          }
        }
      },
      isNotEmpty(value) {
        if (value) {
          return value.trim().length > 0 || false;
        }
        return false;
      },
      isValidEmail(value) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || false;
      },
    },
  }
</script>
<style lang="scss">
  @import '../assets/styles/buttons.scss';
  .login {
    position: relative;
    // width: 55vw;
    .error-msg {
      position: absolute;
      bottom: 10px;
      color: #E1000F;
    }
    .error-auth {
      text-align: center;
      width: 100%;
      display: inline-block;
      bottom: 0px;
      left: 0px;
      font-size: 14px;
    }
    .closeBtn {
      color: black !important;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .content {
      background-color: #FFF;
      padding: 100px;
      .title {
        text-align: center;
        p {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .form {
        p {
          padding-left: 20px;
          margin-block-end: 0;
        }
        .link {
          font-size: 14px;
          text-decoration: underline;
        }
      }
      .rememberMe {
        padding-top: 10px;
        .v-input__control {
          width: 100%;
          .v-input__slot {
            align-items: center;
            justify-content: center;
          }
          .v-label {
            color: black;
          }
        }
      }
      .cnxButton {
        height: 60px;
      }
      .mention {
        padding-top: 40px;
        text-align: center;
        font-size: 14px;
        p {
          margin-block-end: 0;
        }
        a {
          font-size: 14px;
          text-decoration: underline;
        }
      }
    }
  }
</style>